import React from 'react';
import { Chip, Drawer, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import moment from 'moment';

interface Props {
    open: boolean;
    onClose: () => void;
    defaultData?: SubscriptionData;
}

interface SubscriptionData {
    is_cancelled?: number;
    subscription_plan?: string;
    plan_period?: string;
    period_type?: string;
    plan_amount?: string;
    start_date?: string;
    end_date?: string;
    expires_in_days?: number;
    billing_date?: string;
    total_amount?: string;
    payment_method?: string;
    payment_status?: string;
    payment_ref_number?: string;
    is_subscription_approved?: number;
}

const renderChip = (label: string, color: "success" | "error") => (
    <Chip label={label} color={color} variant="outlined" size="small" />
);

const renderStatus = (data: SubscriptionData) => {
    if (data.is_cancelled === 1) return renderChip('Cancelled', 'error');
    if (data.is_cancelled === 0) return renderChip('Active', 'success');
    if (data.expires_in_days !== undefined && data.expires_in_days < 0) return renderChip('Expired', 'error');
    return null;
};

const KeyValueRow: React.FC<{ label: string, value: React.ReactNode }> = ({ label, value }) => (
    <TableRow>
        <TableCell component="th" scope="row">
            <Typography variant="subtitle2">{label}</Typography>
        </TableCell>
        <TableCell>{value}</TableCell>
    </TableRow>
);

const View: React.FC<Props> = ({ onClose, open, defaultData }) => {
    const rows = [
        { label: 'Status', value: renderStatus(defaultData || {}) },
        { label: 'Plan Name', value: defaultData?.subscription_plan ?? 'N/A' },
        { label: 'Plan Period', value: `${defaultData?.plan_period ?? 'N/A'} ${defaultData?.period_type ?? ''}` },
        { label: 'Plan Amount', value: defaultData?.plan_amount ?? 'N/A' },
        { label: 'Start Date', value: defaultData?.start_date ? moment(defaultData.start_date).format('YYYY-MM-DD') : 'N/A' },
        { label: 'End Date', value: defaultData?.end_date ? moment(defaultData.end_date).format('YYYY-MM-DD') : 'N/A' },
        { label: 'Remaining Days', value: defaultData?.expires_in_days ?? 'N/A' },
        { label: 'Billing Date', value: defaultData?.billing_date ? moment(defaultData.billing_date).format('YYYY-MM-DD') : 'N/A' },
        { label: 'Billing Amount', value: defaultData?.total_amount ?? 'N/A' },
        { label: 'Payment Method', value: defaultData?.payment_method ?? 'N/A' },
        { label: 'Payment Status', value: defaultData?.payment_status ?? 'N/A' },
        { label: 'Payment Ref', value: defaultData?.payment_ref_number ?? 'N/A' },
        { label: 'Approved', value: renderChip(defaultData?.is_subscription_approved === 1 ? 'Yes' : 'No', defaultData?.is_subscription_approved === 1 ? 'success' : 'error') },
    ];

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            transitionDuration={600}
            PaperProps={{ elevation: 9 }}
            SlideProps={{ unmountOnExit: true }}
        >
            <TableContainer sx={{ marginY: 2, minWidth: 400, maxWidth: 800 }}>
                <Table size="small">
                    <TableBody>
                        {rows.map((row, index) => (
                            <KeyValueRow key={index} label={row.label} value={row.value} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Drawer>
    );
};

export default View;
