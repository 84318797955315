import { Cancel, CheckCircle } from "@mui/icons-material";

interface Props {
    value: boolean | number | string;
}

const BooleanIcon = ({ value, ...rest }: Props) => {
    return (value == true || value == 1) ? (
        <CheckCircle fontSize="medium" color="success" />
    ) : (
        <Cancel fontSize="medium" color="warning" />
    )
}

export { BooleanIcon };