import { Chip } from "@mui/material";
import { avatarOptions, ChipColumnOptions, BooleanIconOption, dateTimeOptions, priceOption } from ".";
import { statusOptions } from "..";

export const masterColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const faqColumns = (formatMessage: any) => [
  {
    name: "type",
    label: formatMessage({ id: "type" }),
  },
  {
    name: "question",
    label: formatMessage({ id: "question" }),
  },
  {
    name: "order",
    label: formatMessage({ id: "order" }),
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const roleColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "guard_name",
    label: formatMessage({ id: "guard-name" }),
  },
];

export const accountColumns = (formatMessage: any) => [
  {
    name: "logo_path",
    label: formatMessage({ id: "logo" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_verified",
    label: formatMessage({ id: "verified" }),
    ...BooleanIconOption
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
    disableSort: true,
    disableFilter: true,
  },
];

export const userColumns = (formatMessage: any) => [
  {
    name: "email",
    label: formatMessage({ id: "email" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "full_name",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "role",
    label: formatMessage({ id: "role" }),
    disableSort: true,
    disableFilter: true,
    options: {
      customBodyRender: (value: any) => {
        return value?.map((item: any, index: number) => (
          <Chip key={index} label={item} sx={{ marginLeft: 1 }} size="small" color="info" variant="outlined" />
        ));
      },
    },
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const subscriptionColumns = (formatMessage: any) => [
  {
    name: "full_name",
    label: formatMessage({ id: "user" }),
  },
  {
    name: "subscription_plan",
    label: formatMessage({ id: "subscription-plan" }),
  },
  {
    name: "start_date",
    label: formatMessage({ id: "start-date" }),
    ...dateTimeOptions,
  },
  {
    name: "end_date",
    label: formatMessage({ id: "end-date" }),
    ...dateTimeOptions,
  },
  {
    name: "expires_in_days",
    label: formatMessage({ id: "expires_in" }),
    options: {
      customBodyRender: (value: any) => `${value || 0} days`,
    },
  }
];


export const subscriptionFeatureColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const subscriptionPlanColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "plan_period_type",
    label: formatMessage({ id: "plan-period" }),
  },
  {
    name: "amount",
    label: formatMessage({ id: "amount" }),
    ...priceOption
  },
  {
    name: "saved_amount",
    label: formatMessage({ id: "saved-amount" }),
    ...priceOption
  },
  {
    name: "total_amount",
    label: formatMessage({ id: "total-amount" }),
    ...priceOption
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const surveyColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "type",
    label: formatMessage({ id: "type" }),
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const medicationColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "created_at",
    label: formatMessage({ id: "created-date" }),
    ...dateTimeOptions,
  },
];


export const inquiryColumns = (formatMessage: any) => [
  {
    name: "full_name",
    label: formatMessage({ id: "full_name" }),
  },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
  {
    name: "subject",
    label: formatMessage({ id: "subject" }),
  },
  {
    name: "message",
    label: formatMessage({ id: "message" }),
  },
  {
    name: "created_at",
    label: formatMessage({ id: "created-date" }),
    ...dateTimeOptions,
  },
];


export const testimonialColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "designation",
    label: formatMessage({ id: "designation" }),
  },
  {
    name: "order",
    label: formatMessage({ id: "order" }),
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const bannerColumns = (formatMessage: any) => [
  {
    name: "platform",
    label: formatMessage({ id: "platform" }),
  },
  {
    name: "image",
    label: formatMessage({ id: "image" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const newsletterColumns = (formatMessage: any) => [
  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "created_at",
    label: formatMessage({ id: "created-date" }),
    ...dateTimeOptions,
  },
];

export const feedbackColumns = (formatMessage: any) => [
  {
    name: "email",
    label: formatMessage({ id: "email" }),
  },
  {
    name: "message",
    label: formatMessage({ id: "message" }),
  },
  {
    name: "created_at",
    label: formatMessage({ id: "created-date" }),
    ...dateTimeOptions,
  },
];
