import { forwardRef } from "react";

import { Card, CardHeader, CardContent, styled } from "@mui/material";

interface Props {
  children:
    | React.ReactNode
    | React.ReactElement
    | React.ReactNode[]
    | React.ReactElement[];
  title: string;
  showHeader?:boolean
}

const Header = styled(CardHeader)(({ theme }: any) => ({
  padding: theme.spacing(2, 2),
  background: theme.palette.primary.lighter,
  textTransform:'capitalize'
}));

const CardLayout = forwardRef(
  ({ children, title,showHeader,...other }: Props, ref: any) => {
    return (
      <Card sx={{ marginY : 2 }}>
       {showHeader && <Header title={title}/>}
        <CardContent sx={{ padding: 2 }}>{children}</CardContent>
      </Card>
    );
  }
);

CardLayout.defaultProps = {
  title: "",
  showHeader:true
};

export default CardLayout;
