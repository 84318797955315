// @mui
import { Grid } from "@mui/material";
import PageLayout from "layouts/PageLayout";

import WorkIcon from '@mui/icons-material/Work';

import { HoverLoader } from "components";
import { AppWidgetSummary } from "sections/dashboard/AppWidgetSummary";
import { useEffect, useState } from "react";
import { services } from "redux/services";
import { useToastMessage } from "hooks";

export default function DashboardApp() {
  const { toastMessage } = useToastMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<any>({
    user: 0,
  });

  useEffect(() => {
    setLoading(true);
    services
      .dashboard()
      .then((res: any) => {
        const response = res?.result;
        setDashboard(response);
      })
      .catch((err) => {
        toastMessage(JSON.stringify(err?.data?.message) || "Something wrong !", 'error');
      }).finally(() => setTimeout(() => {
        setLoading(false);
      }, 1000));
  }, []);

  return (
    <PageLayout title="Dashboard" isList={false} hideToolbar>
      {loading && <HoverLoader />}
      <Grid container spacing={2} marginBottom={3}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <AppWidgetSummary
            title="User"
            total={dashboard?.user}
            color="secondary"
            icon={<WorkIcon />}
            to={`/admin/users`}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}
