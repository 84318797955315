import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { CustomCheckBox, CustomTextArea, CustomTextBox, CustomTextEditor } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { handleAxiosError, validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, setError, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { status: 1, order: 1 }
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [answer, setAnswer] = useState('');

    function onSubmit(data: any) {
        setLoading(true);
        data.type = 'support';
        data.answer = answer;
        data.status = data?.status ? 1 : 0;
        if (formMode === 'update') data.id = selectedData?.id;
        const apiFunc = formMode === 'create' ? 'createFaq' : 'updateFaq';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "faq" }));
                closeModal();
                refreshData();
            })
            .catch((error: any) => {
                setLoading(false);
                handleAxiosError(error, toastMessage, formatMessage, setError);
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                type: selectedData.type,
                question: selectedData.question,
                order: selectedData.order,
                status: selectedData.status,
            });
            setAnswer(selectedData.answer)
        }

    }, [formMode === 'update']);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='sm'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextArea
                        name="question"
                        control={control}
                        placeholder={formatMessage({ id: "question" })}
                        required
                        error={errors?.question}
                        minRows={5}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextEditor
                        formLabel={formatMessage({ id: 'answer' })}
                        name="answer"
                        defaultValue={answer}
                        onChange={(value) => setAnswer(value)}
                        error={errors?.answer}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        type='number'
                        name="order"
                        defaultValue={1}
                        control={control}
                        placeholder={formatMessage({ id: "order" })}
                        rules={{ required: true }}
                        error={errors?.order}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="status"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;
