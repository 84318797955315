import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';

import Box from "@mui/material/Box";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Paper } from "@mui/material";

type TabContainerProps = {
    onChange?: (value: string) => void;
    tabs: { [key: string]: string | string | any }[];
    height?: string;
    active: string | number;
} & Omit<TabsProps, "onChange">;

const useStyles = makeStyles()((theme) => ({
    customTabRoot: {
        color: theme.palette.primary.main,
        minHeight: "38px",
        height: "38px",
    },
    customTabIndicator: {
        height: "0px"
    },
    tabRoot: {
        textTransform: "uppercase",
        minHeight: "38px",
        height: "38px",
        transition: 'all 0.3s ease-in',
        color: theme.palette.text.secondary,
        borderRight: `1px solid ${theme.palette.divider}`,
        "&:hover": {
            backgroundColor: theme.palette.grey[400],
            // color: theme.palette.text.primary,
        }
    },
    activeTabRoot: {
        background: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} !important`,
        fontWeight: "bolder"
    }
})
);

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        className: 'text-13 font-regular'
    }
}

const CustomTab = ({ onChange, active, tabs, height,...rest }: TabContainerProps) => {
    const { classes } = useStyles();
    const [activeTab, setActiveTab] = useState<number>();
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (active) {
            setActiveTab(tabs.findIndex(x => x.value == active));
        }
    }, [active]);

    return (
        <Box marginBottom={1} sx={{ overflow: "hidden" }} component={Paper} elevation={2}>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={activeTab}
                onChange={(_: any, value: number) => {
                    setActiveTab(value);
                    if (onChange) {
                        const active = tabs.find((_x: any, i: number) => i === value) as any;
                        onChange(active?.value);
                    }
                }}
                classes={{
                    root: classes.customTabRoot,
                    indicator: classes.customTabIndicator,
                }}
                {...rest}
            >
                {tabs.map((tab, index) => (
                    <Tab
                        {...tab.icon && {
                            icon: tab.icon,
                            iconPosition: "start"
                        }}
                        key={index}
                        label={formatMessage({ id: tab.label })}
                        {...a11yProps(index)}
                        classes={{ root: classes.tabRoot, selected: classes.activeTabRoot + " font-medium" }}
                    />
                ))}
            </Tabs>
        </Box >
    )
}

export { CustomTab };