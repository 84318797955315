import { MASTER_API_ENDPOINTS } from "redux/masterApiEndpoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any;
}

export const masterServices = {

  createRole: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_ROLE, body),
  updateRole: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_ROLE, body),
  getAllRole: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_ROLE, body),
  deleteRole: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_ROLE, body),

  createPaymentMethod: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_PAYMENT_METHOD, body),
  updatePaymentMethod: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_PAYMENT_METHOD, body),
  getAllPaymentMethod: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_PAYMENT_METHOD, body),
  deletePaymentMethod: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_PAYMENT_METHOD, body),

  createPermission: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_PERMISSION, body),
  updatePermission: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_PERMISSION, body),
  getAllPermission: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_PERMISSION, body),
  deletePermission: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_PERMISSION, body),
  autoUpdatePermission: () => new AxiosService().post(MASTER_API_ENDPOINTS.UPDATE_PERMISSION_AUTO, {}),

  createProfession: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_PROFESSION, body),
  updateProfession: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_PROFESSION, body),
  getAllProfession: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_PROFESSION, body),
  deleteProfession: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_PROFESSION, body),

  createSubscriptionFeature: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_SUBSCRIPTION_FEATURE, body),
  updateSubscriptionFeature: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_SUBSCRIPTION_FEATURE, body),
  getAllSubscriptionFeature: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SUBSCRIPTION_FEATURE, body),
  deleteSubscriptionFeature: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SUBSCRIPTION_FEATURE, body),

  createSubscriptionPlan: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_SUBSCRIPTION_PLAN, body),
  updateSubscriptionPlan: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_SUBSCRIPTION_PLAN, body),
  getAllSubscriptionPlan: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SUBSCRIPTION_PLAN, body),
  getSubscriptionPlan: () => new AxiosService().get(MASTER_API_ENDPOINTS.GET_SUBSCRIPTION_PLAN),
  deleteSubscriptionPlan: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SUBSCRIPTION_PLAN, body),

  createSurvey: (body: ParamsState) => new AxiosService().postForm(MASTER_API_ENDPOINTS.CREATE_SURVEY, body),
  updateSurvey: (body: ParamsState) => new AxiosService().putForm(MASTER_API_ENDPOINTS.UPDATE_SURVEY, body),
  getAllSurvey: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SURVEY, body),
  deleteSurvey: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SURVEY, body),

  createSurveyOption: (body: ParamsState) => new AxiosService().postForm(MASTER_API_ENDPOINTS.CREATE_SURVEY_OPTION, body),
  updateSurveyOption: (body: ParamsState) => new AxiosService().putForm(MASTER_API_ENDPOINTS.UPDATE_SURVEY_OPTION, body),
  getAllSurveyOption: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SURVEY_OPTION, body),
  deleteSurveyOption: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SURVEY_OPTION, body),

  createMedication: (body: ParamsState) => new AxiosService().postForm(MASTER_API_ENDPOINTS.CREATE_MEDICATION, body),
  updateMedication: (body: ParamsState) => new AxiosService().putForm(MASTER_API_ENDPOINTS.UPDATE_MEDICATION, body),
  getAllMedication: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_MEDICATION, body),
  deleteMedication: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_MEDICATION, body),

  createMedicationType: (body: ParamsState) => new AxiosService().postForm(MASTER_API_ENDPOINTS.CREATE_MEDICATION_TYPE, body),
  updateMedicationType: (body: ParamsState) => new AxiosService().putForm(MASTER_API_ENDPOINTS.UPDATE_MEDICATION_TYPE, body),
  getAllMedicationType: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_MEDICATION_TYPE, body),
  deleteMedicationType: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_MEDICATION_TYPE, body),

};
