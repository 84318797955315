import { BASE_URI } from "utils/axios";

export const API_ENDPOINTS = {
	LOGIN: `${BASE_URI}/login`,
	CHANGE_PASSWORD: `${BASE_URI}/users/change-password`,
	GET_ACCOUNT: `${BASE_URI}/get-account`,
	SELECT_ACCOUNT: `${BASE_URI}/select-account`,
	LOGOUT: `${BASE_URI}/logout`,
	DASHBOARD: `${BASE_URI}/dashboard`,

	LIST_ACCOUNT: `${BASE_URI}/accounts/list`,
	GET_ACCOUNT_BY_ID: `${BASE_URI}/accounts/get`,
	CREATE_ACCOUNT: `${BASE_URI}/accounts/save`,
	UPDATE_ACCOUNT: `${BASE_URI}/accounts/update`,
	DELETE_ACCOUNT: `${BASE_URI}/accounts/delete`,
	VERIFY_ACCOUNT: `${BASE_URI}/accounts/verify`,
	UPDATE_ACCOUNT_STATUS: `${BASE_URI}/accounts/update-status`,
	SAVE_ACCOUNT_FILE: `${BASE_URI}/account-documents/save`,
	DELETE_ACCOUNT_FILE: `${BASE_URI}/account-documents/delete`,

	LIST_USER: `${BASE_URI}/account-users/list`,
	GET_USER_BY_UUID: `${BASE_URI}/account-users/get`,
	CREATE_USER: `${BASE_URI}/account-users/save`,
	UPDATE_USER: `${BASE_URI}/account-users/update`,
	UPDATE_USER_FLAG: `${BASE_URI}/account-users/update-flag`,
	DELETE_USER: `${BASE_URI}/account-users/delete`,
	USER_LOOKUP: `${BASE_URI}/account-users/lookup`,

	GENERATE_REPORT: `${BASE_URI}/report/generate`,

	GET_SUBSCRIPTION: `${BASE_URI}/subscription/get`,
	LIST_SUBSCRIPTION: `${BASE_URI}/subscription/list`,
	CREATE_SUBSCRIPTION: `${BASE_URI}/subscription/choose-plan`,
	CANCEL_SUBSCRIPTION: `${BASE_URI}/subscription/cancel`,
	GET_SUBSCRIPTION_PAYMENT_METHOD: `${BASE_URI}/subscription-payment-method`,
	
	LIST_FAQ: `${BASE_URI}/faqs/list`,
	CREATE_FAQ: `${BASE_URI}/faqs/save`,
	UPDATE_FAQ: `${BASE_URI}/faqs/update`,
	DELETE_FAQ: `${BASE_URI}/faqs/delete`,

	LIST_TESTIMONIAL: `${BASE_URI}/testimonials/list`,
	CREATE_TESTIMONIAL: `${BASE_URI}/testimonials/save`,
	UPDATE_TESTIMONIAL: `${BASE_URI}/testimonials/update`,
	DELETE_TESTIMONIAL: `${BASE_URI}/testimonials/delete`,

	LIST_BANNER: `${BASE_URI}/banners/list`,
	CREATE_BANNER: `${BASE_URI}/banners/save`,
	UPDATE_BANNER: `${BASE_URI}/banners/update`,
	DELETE_BANNER: `${BASE_URI}/banners/delete`,

	GET_BILLING: `${BASE_URI}/subscription/billing`,
	SAVE_BILLING: `${BASE_URI}/subscription/billing-payment`,

	LIST_INQUIRY: `${BASE_URI}/inquiries`,
	LIST_NEWSLETTER: `${BASE_URI}/newsletters`,
	LIST_FEEDBACK: `${BASE_URI}/feedbacks`,
};
