import { BASE_URI } from "utils/axios";

export const MASTER_API_ENDPOINTS = {
	// Master Requests
	LIST_ROLE: `${BASE_URI}/roles/list`,
	CREATE_ROLE: `${BASE_URI}/roles/save`,
	UPDATE_ROLE: `${BASE_URI}/roles/update`,
	DELETE_ROLE: `${BASE_URI}/roles/delete`,

	LIST_SUBSCRIPTION_FEATURE: `${BASE_URI}/subscription-plan-features/list`,
	CREATE_SUBSCRIPTION_FEATURE: `${BASE_URI}/subscription-plan-features/save`,
	UPDATE_SUBSCRIPTION_FEATURE: `${BASE_URI}/subscription-plan-features/update`,
	DELETE_SUBSCRIPTION_FEATURE: `${BASE_URI}/subscription-plan-features/delete`,

	LIST_PAYMENT_METHOD: `${BASE_URI}/payment-methods/list`,
	CREATE_PAYMENT_METHOD: `${BASE_URI}/payment-methods/save`,
	UPDATE_PAYMENT_METHOD: `${BASE_URI}/payment-methods/update`,
	DELETE_PAYMENT_METHOD: `${BASE_URI}/payment-methods/delete`,

	LIST_PERMISSION: `${BASE_URI}/permissions/list`,
	CREATE_PERMISSION: `${BASE_URI}/permissions/save`,
	UPDATE_PERMISSION: `${BASE_URI}/permissions/update`,
	DELETE_PERMISSION: `${BASE_URI}/permissions/delete`,
	UPDATE_PERMISSION_AUTO: `${BASE_URI}/permissions/auto-update`,

	LIST_PROFESSION: `${BASE_URI}/professions/list`,
	CREATE_PROFESSION: `${BASE_URI}/professions/save`,
	UPDATE_PROFESSION: `${BASE_URI}/professions/update`,
	DELETE_PROFESSION: `${BASE_URI}/professions/delete`,

	LIST_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/list`,
	GET_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/get`,
	CREATE_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/save`,
	UPDATE_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/update`,
	DELETE_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/delete`,

	LIST_SURVEY: `${BASE_URI}/survey/list`,
	CREATE_SURVEY: `${BASE_URI}/survey/save`,
	UPDATE_SURVEY: `${BASE_URI}/survey/update`,
	DELETE_SURVEY: `${BASE_URI}/survey/delete`,

	LIST_SURVEY_OPTION: `${BASE_URI}/survey-options/list`,
	CREATE_SURVEY_OPTION: `${BASE_URI}/survey-options/save`,
	UPDATE_SURVEY_OPTION: `${BASE_URI}/survey-options/update`,
	DELETE_SURVEY_OPTION: `${BASE_URI}/survey-options/delete`,

	LIST_MEDICATION: `${BASE_URI}/medications/list`,
	CREATE_MEDICATION: `${BASE_URI}/medications/save`,
	UPDATE_MEDICATION: `${BASE_URI}/medications/update`,
	DELETE_MEDICATION: `${BASE_URI}/medications/delete`,

	LIST_MEDICATION_TYPE: `${BASE_URI}/medication-types/list`,
	CREATE_MEDICATION_TYPE: `${BASE_URI}/medication-types/save`,
	UPDATE_MEDICATION_TYPE: `${BASE_URI}/medication-types/update`,
	DELETE_MEDICATION_TYPE: `${BASE_URI}/medication-types/delete`,
};
