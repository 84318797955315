import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import LinearProgress from '@mui/material/LinearProgress';
import Search from '@mui/icons-material/Search';

import { useAsyncDebounce } from 'hooks';
import { CustomButton } from "components";
import PopupTooltip from 'components/PopupTooltip';
import { Grid, OutlinedInput, styled, Toolbar } from '@mui/material';

export const CustomToolbar = React.memo((props: any) => {
    const { setTableState, columns, loading } = props;

    return (
        <>
            <SearchFilter setTableState={setTableState} columns={columns} />
            {loading && <LinearProgress sx={{ position: "absolute", width: "100%", left: 0, bottom: 0 }} />}
        </>
    )
});


const RootStyle = styled(Toolbar)(({ theme }: any) => ({
    height: 80,
    padding: '0px !important'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
    // marginLeft: 10,
    // width: 150,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        // width: 320, 
        boxShadow: theme.customShadows.z8
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));


const SearchFilter = React.memo(({ columns, setTableState, numSelected }: any) => {
    const [searchText, setSearchText] = useState('');
    const [searchFilterColumnOpen, setSearchFilterColumnOpen] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    let filterColumns = columns?.filter((col: any) => col?.hideGlobalSearchFilter ? false : col.name).reduce((acc: any, curr: any) => (acc = [...acc, ...(curr?.extendedColumns ? curr?.extendedColumns : [{ label: curr.label, name: curr?.primaryColumnName ?? curr.name }])]), []);

    useEffect(() => {
        setSelectedColumns(filterColumns.map((col: any) => col.name));
    }, []);

    function onChangeSearchFilter(colName: string) {
        let isColExists = selectedColumns.some(selectedCol => selectedCol === colName);
        let searchFilters = [];

        if (isColExists) {
            searchFilters = selectedColumns.filter(col => col !== colName);
        } else {
            searchFilters = [...selectedColumns, colName];
        }
        setSelectedColumns(searchFilters);

        if (searchFilters.length && searchText) {
            onFilter(searchText, searchFilters);
        }
    }

    function onFilter(value: string | null, selectedColumns: string[]) {
        const data = selectedColumns.map(col => ({ "member": col, "value": value ?? "" }))

        if (data) {
            setTableState((prevState: any) => ({ ...prevState, searchColumnFilters: value ? data : [] }));
        }
    }

    const onChangeSearchQuery = useAsyncDebounce((value: string | null) => {
        if (selectedColumns.length) {
            onFilter(value, selectedColumns);
        }
    }, 400);

    function toggleAllFields(status: boolean) {
        let searchFilters = [];

        if (status) {
            searchFilters = filterColumns.map((col: any) => col.name);
        }
        setSelectedColumns(searchFilters);
        if (searchText) {
            onFilter(searchText, searchFilters);
        }
    }

    return (
        <RootStyle
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter',
                })
            }}
        >
            {filterColumns?.length > 0 && (
                <Grid container spacing={1}>
                    <Grid item lg={2} md={2} sm={4} xs={12}>
                        <PopupTooltip
                            content={(
                                <GlobalSearchColumnFilter
                                    filterColumns={filterColumns}
                                    selectedColumns={selectedColumns}
                                    onChangeSearchFilter={onChangeSearchFilter}
                                    toggleAllFields={toggleAllFields}
                                />
                            )}
                            open={searchFilterColumnOpen}
                            placement="bottom-start"
                            onClose={() => setSearchFilterColumnOpen(false)}
                        >
                            <CustomButton
                                // style={{ marginLeft: -32 }}
                                fullWidth
                                size="small"
                                variant='outlined'
                                onClick={() => setSearchFilterColumnOpen(true)}
                                label="Filter"
                                endIcon={<ArrowDropDown style={{ fontSize: 30 }} />}
                            />
                        </PopupTooltip>
                    </Grid>
                    <Grid item lg={6} md={6} sm={8} xs={12}>
                        <SearchStyle
                            fullWidth
                            placeholder="Search..."
                            value={searchText}
                            size="small"
                            onChange={e => {
                                setSearchText(e.target.value);
                                onChangeSearchQuery(e.target.value);
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            }
                        />
                    </Grid>
                </Grid>
            )
            }
        </RootStyle>
    )
})

const GlobalSearchColumnFilter = React.memo(({ filterColumns, selectedColumns, onChangeSearchFilter, toggleAllFields }: any) => {
    const [allFieldsSelected, setAllFiledSelected] = useState(true);
    const { formatMessage } = useIntl();

    useEffect(() => {
        setAllFiledSelected(selectedColumns.length === filterColumns.length);
    }, [selectedColumns]);

    return (
        <Grid container spacing={1} padding={2}>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            size="small"
                            checked={allFieldsSelected}
                            onChange={e => {
                                setAllFiledSelected(e.target.checked);
                                toggleAllFields(e.target.checked);
                            }}
                        />
                    }
                    label={<Typography variant="body2">{formatMessage({ id: "all-fields" })}</Typography>}
                />
            </Grid>

            {filterColumns.map((col: any, index: number) => (
                <Grid item xs={12} key={index}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                size="small"
                                checked={selectedColumns.some((selectedCol: string) => selectedCol === col.name)}
                                onChange={() => onChangeSearchFilter(col.name)}
                                name="checkedB"
                            />
                        }
                        label={<Typography variant="body2">{col.label}</Typography>}
                    />
                </Grid>
            ))}

        </Grid>
    )
})