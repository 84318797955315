import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { Controller } from 'react-hook-form';
import { createNumberMask } from 'utils/global';
import * as React from 'react';
import { FormErrorMessage } from './FormErrorMessage';
import { Box, Typography } from '@mui/material';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

type CurrencyInputProps = {
  name: string;
  control: any;
  rules?: any;
  label?: any;
  containerClassName?: string;
  error?: any;
  required?: boolean;
  defaultValue?: any;
  onValueChange?: any;
  maskOptions?: {
    prefix?: string;
    suffix?: string;
    includeThousandsSeparator?: boolean;
    thousandsSeparatorSymbol?: string;
    allowDecimal?: boolean;
    decimalSymbol?: string;
    decimalLimit?: number;
    requireDecimal?: boolean;
    allowNegative?: boolean;
    allowLeadingZeroes?: boolean;
    integerLimit?: number;
  };
};

const MaskTextBox = React.memo(
  ({
    name,
    control,
    label,
    rules,
    error,
    defaultValue,
    containerClassName,
    required,
    onValueChange,
    maskOptions,
    ...inputProps
  }: CurrencyInputProps & MaskedInputProps) => {
    const currencyMask = createNumberMask({
      ...defaultMaskOptions,
      ...maskOptions,
    });

    return (
      <Box sx={{ width: '100%' }}>
        {label && (
          <Typography>
            {label} {required ? <Typography component="span" color="red">*</Typography> : ''}
          </Typography>
        )}

        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue ?? ''}
          render={({ field: { onChange, onBlur, value } }) => (
            <MaskedInput
              onChange={(value: any) => {
                onChange(value);
                onValueChange(value);
              }}
              style={{ width: '100%', height: 56, border: '1px solid #ddd', padding: 12 }}
              onBlur={onBlur}
              value={value}
              {...inputProps}
              mask={currencyMask}
            />
          )}
        />

        {/* error message */}
        <FormErrorMessage error={error} />
      </Box>
    );
  },
) as any;

MaskTextBox.defaultProps = {
  onValueChange: () => console.log(),
};
export { MaskTextBox };
