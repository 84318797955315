// material
import { alpha, styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Chip,
  Typography,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { useDate } from "hooks";
import { RootReducerState } from "utils/types";
import useResponsive from "hooks/useResponsive";
import { updateUtility } from "redux/actions";
// components
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import Searchbar from "./Searchbar";
import { useState } from "react";
import ChangePasswordForm from "pages/user/ChangePasswordForm";

const APPBAR_MOBILE = 65;
const APPBAR_DESKTOP = 65;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.action.hover}`,
  },
}));

export default function DashboardNavbar() {
  const { userData, toggleSidebarMenu } = useSelector(({ auth, utilityReducer }: RootReducerState) => ({ userData: auth.data, toggleSidebarMenu: utilityReducer.toggleSidebarMenu }));
  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const dispatch = useDispatch();
  const DRAWER_WIDTH = toggleSidebarMenu ? 239 : 0;
  const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    background: theme.palette.grey[100],
    // color: theme.palette.grey[50],
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
  }));

  const { date, time, wish } = useDate();
  const isDesktop = useResponsive("up", "lg");

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={() => dispatch(updateUtility({ toggleSidebarMenu: !toggleSidebarMenu }))}
          sx={{ mr: 1, color: "primary.light", display: { lg: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Searchbar />
        {isDesktop && (<>
          <IconButton
            onClick={() => dispatch(updateUtility({ toggleSidebarMenu: !toggleSidebarMenu }))}
            sx={{ mr: 1, color: "primary.light", display: { lg: "block" } }}
          >
            <MenuIcon color="inherit" />
          </IconButton>

          <Typography color="primary.light" variant="subtitle2" textTransform="capitalize">
            {wish + (userData?.user?.full_name || 'Admin')}
          </Typography>
        </>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {isDesktop && (
            <Chip
              label={date + " " + time}
              sx={{ color: "primary.main" }}
            />
          )}
          <LanguagePopover />
          <NotificationsPopover />
          <AccountPopover setOpenPassword={setOpenPassword} />
        </Stack>
      </ToolbarStyle>
      {openPassword &&
        <ChangePasswordForm open={openPassword} onClose={() => { setOpenPassword(false) }} />
      }
    </RootStyle>
  );
}
