import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { CustomCheckBox,CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { handleAxiosError, validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, setError, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { status: 1, options: [] }
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        data.status = data?.status ? 1 : 0;
        if (formMode === 'update') data.id = selectedData?.id;
        const apiFunc = formMode === 'create' ? 'createMedication' : 'updateMedication';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "medication" }));
                closeModal();
                refreshData();
            })
            .catch((error: any) => {
                setLoading(false);
                handleAxiosError(error,toastMessage,formatMessage,setError);
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                name: selectedData.name,
                description: selectedData.description,
                side_effects: selectedData.side_effects,
                warning: selectedData.warning,
                status: selectedData.status,
            });
        }

    }, [formMode === 'update']);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='sm'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "name" })}
                        name="name"
                        control={control}
                        error={errors?.name}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        name="description"
                        control={control}
                        placeholder={formatMessage({ id: "description" })}
                        minRows={5}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        name="side_effects"
                        control={control}
                        placeholder={formatMessage({ id: "side-effects" })}
                        minRows={5}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <CustomTextArea
                        name="warning"
                        control={control}
                        placeholder={formatMessage({ id: "warning" })}
                        minRows={5}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="status"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;
