import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { Fragment, useEffect, useState } from 'react';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { Box, Grid } from '@mui/material';
import { AUTH_SUBSCRIPTION_STATUS } from 'redux/actionTypes';
import { useDispatch } from 'react-redux';
import { CustomDialog } from 'components';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function PaymentModal({ open, handleClose }: any) {
    const { toastMessage } = useToastMessage();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        services.getSubscriptionPaymentMethod()
            .then((res: any) => {
                setPaymentMethod(res.result);
            })
            .catch((err) => {
                toastMessage(JSON.stringify(err?.data?.message) || formatMessage({ id: "opps-error-message" }), 'error');
            });
    }, [])

    const handleSubmit = () => {
        services.saveBilling({ payment_method_id: selectedMethod })
            .then((res: any) => {
                handleClose();
                setOpenDialog(false);
                dispatch({ type: AUTH_SUBSCRIPTION_STATUS, payload: { has_subscribed: 1 } });
                navigate('/admin/dashboard')
                toastMessage("Congratulations! You have successfully subscribed.");
            })
            .catch((err) => {
                toastMessage(JSON.stringify(err?.data?.message) || formatMessage({ id: "opps-error-message" }), 'error');
            });
    }

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Payment Method
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {paymentMethod?.map((pay: any, ind: number) => <Grid item key={ind} xs={6}>
                            <Box sx={{ padding: 2, border: 1, cursor: 'pointer', background: selectedMethod == pay.id ? '#DDD' : '' }} onClick={() => setSelectedMethod(pay.id)}>
                                <Typography>{pay.name}</Typography>
                            </Box>
                        </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant='contained' onClick={() => setOpenDialog(true)}>
                        Submit
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            {openDialog && (
                <CustomDialog
                    open={openDialog}
                    onDisagree={() => setOpenDialog(false)}
                    onAgree={handleSubmit}
                    title="Are you sure you want to purchase this plan?"
                    subTitle="After purchase, plan changes are not permitted until the subscription expires."
                />
            )}
        </Fragment>
    );
}