import { useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import shadows, { customShadows } from './shadows';

export default function ThemeProvider({ children }: any) {
  const themeOptions: any = useMemo(
    () => ({
      mode: 'light',
      palette,
      shape: { borderRadius: 0 },
      typography,
      shadows,
      customShadows,
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              // custom style
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              // custom style
            }
          }
        },

        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              // custom style
            }
          }
        },

        MuiInput: {
          styleOverrides: {
            root: {
              // custom style
            }
          }
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              // custom style
            }
          }
        },

        MuiChip: {
          styleOverrides: {
            root: {
              borderRadius: '0px !important'
              // custom style
            },
          }
        },

      },
    }),
    []
  );

  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
