import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import Drawer, { DrawerProps } from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { ButtonGroup, CustomButton } from '../button';
import { Container, styled } from '@mui/system';
import { Save } from '@mui/icons-material';
import Cancel from '@mui/icons-material/Cancel';

interface Props {
    open: boolean;
    children: React.ReactElement | React.ReactElement[];
    title?: string | ReactNode;
    onCancel: () => void;
    onSubmit?: () => void;
    cancelLabel?: string | ReactNode;
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    formMode: 'create' | 'update' | 'view';
    style?: any
}

const DrawerHeader = styled('div')(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3),
    gap: 36,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const CustomDrawer = (props: Props & DrawerProps) => {
    const { formatMessage } = useIntl();
    const { children, title, open, onCancel, anchor, size, cancelLabel, formMode, style = {}, onSubmit } = props;

    return (
        <Drawer
            open={open}
            anchor={anchor}
            onClose={onCancel}
            transitionDuration={600}
            PaperProps={{ elevation: 9 }}
            SlideProps={{ unmountOnExit: true }}
            sx={style}
        >
            <Container maxWidth={size} disableGutters>
                <DrawerHeader>
                    {title ? <Typography variant="subtitle1" color={"primary"}>{title}</Typography> : <span></span>}
                    <ButtonGroup >
                        <CustomButton
                            size='small'
                            startIcon={<Cancel />}
                            variant="outlined" color="error" onClick={onCancel}>
                            {cancelLabel}
                        </CustomButton>
                        {onSubmit &&
                            <CustomButton
                                size='small'
                                startIcon={<Save color="inherit" fontSize="inherit" />}
                                color="info"
                                onClick={onSubmit}>
                                {formMode === 'create' ? formatMessage({ id: 'submit' }) : formatMessage({ id: 'update' })}
                            </CustomButton>
                        }
                    </ButtonGroup>

                </DrawerHeader>
                <Divider />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {children}
                </Box>
            </Container>
        </Drawer>
    )
};

CustomDrawer.defaultProps = {
    anchor: "right",
    size: 'xs',
    cancelLabel: <FormattedMessage id="close" />
}

export { CustomDrawer };