import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Box, Button, Grid, IconButton } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { handleAxiosError, validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate, surveyTypesOptions } from 'utils/constants';
import { Add, Delete } from '@mui/icons-material';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const optionsInitialState = {
    option_id: null,
    name: '',
    order: '',
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, setValue, setError, register, clearErrors, watch, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { status: 1, options: [] }
    });
    const { fields, append, remove } = useFieldArray<any>({
        control,
        name: "options",
    });

    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState({
        image: '',
    })

    function onSubmit(data: any) {
        setLoading(true);
        data.type = data.type?.value;
        data.image = data?.image?.[0];
        data.status = data?.status ? 1 : 0;
        if (formMode === 'update') data.id = selectedData?.id;
        const apiFunc = formMode === 'create' ? 'createSurvey' : 'updateSurvey';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "survey" }));
                closeModal();
                refreshData();
            })
            .catch((error: any) => {
                setLoading(false);
                handleAxiosError(error,toastMessage,formatMessage,setError);
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                name: selectedData.name,
                type: surveyTypesOptions.find(x => x.value === selectedData.type),
                description: selectedData.description,
                order: selectedData.order,
                options: selectedData?.options?.length > 0 ? selectedData?.options?.map((x: any) => ({ option_id: x.id, name: x.name, order: x.order })) : [],
                status: selectedData.status,
                image: null,
            });
            setFileImage({ image: selectedData.image })
        }

    }, [formMode === 'update',selectedData]);

    const handleDelete = (id: any, index: number) => {
        services.deleteSurveyOption({ id })
            .then((res: any) => {
                refreshData();
                remove(index);
                toastMessage(formatMessage({ id: "delete-message" }));
            })
            .catch((err) => {
                toastMessage(JSON.stringify(err?.data?.message) || formatMessage({ id: "opps-error-message" }), 'error');
            })
    }


    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='sm'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "name" })}
                        name="name"
                        control={control}
                        error={errors?.name}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomSelect
                        options={surveyTypesOptions}
                        label={formatMessage({ id: "type" })}
                        name="type"
                        control={control}
                        error={errors.type}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        name="description"
                        control={control}
                        placeholder={formatMessage({ id: "description" })}
                        minRows={5}
                    />
                </Grid>
                {(watch('type')?.value == 'welcome_survey' || watch('type')?.value == 'feed_back_survey' || watch('type')?.value == 'other') &&
                    <Grid item xs={12}>
                        {fields.map((field: any, index) => (
                            <Box key={field.id} sx={{ display: 'flex', gap: 1, marginY: 1 }}>
                                <CustomTextBox
                                    label={formatMessage({ id: "name" })}
                                    name={`options.${index}.name`}
                                    control={control}
                                    error={errors.options?.[index]?.name}
                                />
                                <CustomTextBox
                                    type='number'
                                    label={formatMessage({ id: "order" })}
                                    name={`options.${index}.order`}
                                    control={control}
                                />
                                <IconButton onClick={() => { if (field?.option_id) { handleDelete(field?.option_id, index) } else remove(index); }}>
                                    <Delete color='error' />
                                </IconButton>
                            </Box>
                        ))}
                        <Button
                            startIcon={<Add />}
                            variant='contained'
                            onClick={() => append(optionsInitialState)}
                        >
                            Add Options
                        </Button>
                    </Grid>
                }
                <Grid item xs={12}>
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        {...register('image', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                        })}
                        label={formatMessage({ id: "image" })}
                        error={errors?.image}
                        defaultFileUrl={fileImage.image}
                        defaultFileName={''}
                        onClear={() => {
                            setValue(`image`, null);
                            clearErrors(`image`);
                        }}
                        onChange={(file: any) => setValue('image', file)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        type='number'
                        label={formatMessage({ id: "order" })}
                        name="order"
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="status"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;
