import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Grid, Paper, Tab, Typography } from '@mui/material';
import { CustomDialog, HoverLoader } from 'components';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import PaymentModal from './PaymentMethod';
import { Discount, Payment, ShoppingBag, Star } from '@mui/icons-material';
import { groupBy } from 'lodash';
import { TabContext, TabList } from '@mui/lab';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'utils/types';

const Form: React.FC = () => {
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const [planData, setPlanData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [paymentOpen, setPaymentOpen] = useState(false);
    const [billingId, setBillingId] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [activeTab, setActiveTab] = useState('month');

    useEffect(() => {
        setLoading(true);
        services.getSubscriptionPlan()
            .then((res: any) => {
                const response = res.result;
                setPlanData(groupBy(response, 'period_type'));
            })
            .catch((err) => {
                toastMessage(JSON.stringify(err?.data?.message) || formatMessage({ id: "opps-error-message" }), 'error');
            }).finally(() => setLoading(false));
    }, [])

    const handleSubmit = () => {
        setLoading(true);
        services.createSubscription({ plan_id: planId })
            .then((res: any) => {
                setBillingId(res.result.id)
                setLoading(false);
                setPaymentOpen(true);
                toastMessage(formatMessage({ id: 'created-message' }, { title: formatMessage({ id: 'subscription' }) }));
            })
            .catch((err) => {
                setLoading(false);
                toastMessage(JSON.stringify(err?.data?.message) || formatMessage({ id: "opps-error-message" }), 'error');
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const onAgree = () => {
        handleSubmit();
        handleClose();
    }

    return (
        <Container maxWidth="xl">
            <TabContext value={activeTab}>
                {loading && <HoverLoader />}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h3" align="center" color="textPrimary" component="p">
                            Subscripton Plan Preview
                        </Typography>
                    </Grid>
                    {Object.keys(planData)?.length > 0 &&
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TabList onChange={(_, value) => setActiveTab(value)} component={Paper}>
                                        {Object.keys(planData)?.sort()?.map((key: string) => <Tab label={key?.toUpperCase()} value={key} key={key} />
                                        )}
                                    </TabList>
                                </Grid>
                                {planData?.[activeTab]?.map((item: any, i: number) => (
                                    <Grid item key={i} xs={12} sm={6} md={6}>
                                        <Card>
                                            <CardHeader
                                                title={item.name}
                                                subheader={item?.subheader}
                                                titleTypographyProps={{ align: 'center' }}
                                                subheaderTypographyProps={{ align: 'center' }}
                                                action={item?.name === 'Pro' ? <Star /> : null}
                                                sx={{
                                                    bgcolor: 'secondary.lighter',
                                                }}
                                            />
                                            <CardContent>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'baseline',
                                                }}>
                                                    <Typography component="h2" variant="h3" color="textPrimary">
                                                        € {item.total_amount}
                                                    </Typography>
                                                    <Typography variant="h6" color="textSecondary">
                                                        / {item.plan_period > 1 && item.plan_period} {item.period_type}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'baseline',
                                                    marginBottom: 2,

                                                }}>
                                                    <Typography component="h6" variant="h6" color="textSecondary" sx={{ textDecoration: "line-through" }} >
                                                        € {(Number(item.total_amount) + Number(item.saved_amount)).toFixed(2)}
                                                    </Typography>
                                                    &nbsp;&nbsp;
                                                    <Typography variant="caption" color="textSecondary">
                                                        € {item.saved_amount} <Discount fontSize='small' />
                                                    </Typography>

                                                </Box>

                                                <ul style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    listStyle: 'none',
                                                }}>
                                                    {item?.features?.map((line: any, index: any) => (
                                                        <Typography component="li" variant="body2" align="center" key={index}>
                                                            {line}
                                                        </Typography>
                                                    ))}
                                                </ul>
                                            </CardContent>
                                            {/* <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                                                {item.billing_info ?
                                                    <Button
                                                        startIcon={<Payment />}
                                                        color='info'
                                                        // fullWidth 
                                                        variant={'contained'}
                                                        onClick={() => {
                                                            setPaymentOpen(true);
                                                            setBillingId(item.billing_info.billing_id);
                                                        }}
                                                    >
                                                        Pay Now
                                                    </Button>
                                                    :
                                                    <Button
                                                        startIcon={<ShoppingBag />}
                                                        variant={'contained'}
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setPlanId(item.id);
                                                        }}
                                                        disabled={(hasBilledPlan || hasSubscribed == 1) ? true : false}
                                                    >
                                                        Buy Now
                                                    </Button>
                                                }
                                            </CardActions> */}
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    }
                </Grid>

                {paymentOpen && <PaymentModal
                    billingId={billingId}
                    open={paymentOpen}
                    handleClose={() => setPaymentOpen(false)}
                />
                }

                {open && (
                    <CustomDialog
                        open={open}
                        onDisagree={handleClose}
                        onAgree={onAgree}
                        title="Are you sure you want to purchase this plan?"
                        subTitle="Once purchased, you cannot change the plan until the subscription expires"
                    />
                )}
            </TabContext>
        </Container>
    )
}

export default Form;