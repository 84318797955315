import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { styled } from '@mui/material/styles';
import { Card, Link, Container, Stack, InputAdornment, Box, Button } from '@mui/material';

import { CustomTextBox } from 'components/forms';
import Person from '@mui/icons-material/Person';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { authReset, login } from 'redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useToastMessage } from 'hooks';
import { RootReducerState } from 'utils/types';
import { useNavigate } from 'react-router-dom';
import BgImage from 'assets/images/bg1.jpeg';
import SeoLayout from 'layouts/SeoLayout';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 380,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toastMessage } = useToastMessage();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { handleSubmit, formState: { errors }, control } = useForm({ mode: 'all' });

  const { loading, authData } = useSelector(
    ({ auth }: RootReducerState) => ({
      loading: auth.loading,
      authData: auth.data,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (authData?.token) {
      navigate('/admin/dashboard');
    } else if (loading) {
      dispatch(authReset());
    }
  }, [authData])

  async function onSubmit(data: any) {
    dispatch(login(data, toastMessage));
  }

  return (
    <Box
      // maxWidth='xl'
      sx={{
        background: `url(${BgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
      <SeoLayout
        title={`Login`}
      />
      <ContentStyle>
        <Card sx={{
          p: 3,
          boxShadow: 'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;'
        }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <CustomTextBox
                label="Email"
                error={errors.email}
                name="email"
                rules={{ required: true }}
                control={control}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" >
                      <span className="inpt_back"><Person color="primary" /></span>
                    </InputAdornment>
                  )
                }}
              />

              <CustomTextBox
                label="Password"
                type={showPassword ? 'text' : 'password'}
                error={errors.password}
                name="password"
                rules={{ required: true }}
                control={control}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Link className="cursorPointer inpt_back" onClick={() => {
                        setShowPassword((prev) => !prev)
                      }}>
                        {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </Link>
                    </InputAdornment>
                  )
                }}
              />
            </Stack>

            <Button fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }}>
              Login
            </Button>
          </form>
        </Card>
      </ContentStyle>
    </Box>
  );
}
