import CircularProgress from "@mui/material/CircularProgress";

const SuspenseLoader = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            height: '80%',
            background: '#FFF',
            borderRadius: 4,
            margin: 10
        }}>
            <div style={{ alignSelf: 'center' }}>
                <CircularProgress
                    color="primary"
                    style={{
                        margin: 0,
                        padding: 0,
                    }}
                />
            </div>
        </div>
    );
};
export { SuspenseLoader };