import React from 'react';
import { Chip, Drawer, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedData?: UserData;
}

interface UserData {
  full_name?: string;
  user_name?: string;
  email?: number;
  phone?: string;
  address?: string;
  device_token?: string;
  status?: number;
}

const renderChip = (label: string, color: "success" | "error") => (
  <Chip label={label} color={color} variant="outlined" size="small" />
);


const KeyValueRow: React.FC<{ label: string, value: React.ReactNode }> = ({ label, value }) => (
  <TableRow>
    <TableCell component="th" scope="row">
      <Typography variant="subtitle2">{label}</Typography>
    </TableCell>
    <TableCell>{value}</TableCell>
  </TableRow>
);

const View: React.FC<Props> = ({ onClose, open, selectedData }) => {
  const rows = [
    { label: 'Name', value: selectedData?.full_name ?? 'N/A' },
    { label: 'Username', value: selectedData?.user_name ?? 'N/A' },
    { label: 'Email', value: selectedData?.email ?? 'N/A' },
    { label: 'Phone', value: selectedData?.phone ?? 'N/A' },
    { label: 'Address', value: selectedData?.address ?? 'N/A' },
    { label: 'Device Token', value: selectedData?.device_token ?? 'N/A' },
    { label: 'Status', value: renderChip(selectedData?.status === 1 ? 'Yes' : 'No', selectedData?.status === 1 ? 'success' : 'error') },
  ];

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      transitionDuration={600}
      PaperProps={{ elevation: 9 }}
      SlideProps={{ unmountOnExit: true }}
    >
      <TableContainer sx={{ marginY: 2, minWidth: 400, maxWidth: 800 }}>
        <Table size="small">
          <TableBody>
            {rows.map((row, index) => (
              <KeyValueRow key={index} label={row.label} value={row.value} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Drawer>
  );
};

export default View;
