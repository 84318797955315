import { useMemo } from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import shadows, { customShadows } from './shadows';

export default function TableThemeProvider({ children }: any) {
  const themeOptions: any = useMemo(
    () => ({
      mode: 'dark',
      palette,
      shape: { borderRadius: 0 },
      typography,
      shadows,
      customShadows,
      components: {
        MuiTable: {
            styleOverrides: {
                root: {
                  boxShadow:customShadows.z24
                }
            }
        },

        MuiToolbar: {
            styleOverrides: {
                root: {
                    paddingLeft: '8px !important',
                    paddingRight: '8px !important',
                    // background: '#f5f5f5',
                },
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    background: palette.grey[300],
                    fontWeight: 600,
                    color: palette.grey[700],
                    padding: 12,
                    whiteSpace: "nowrap",
                },
                body: {
                    padding: 12,
                    whiteSpace: "nowrap",
                },
            }
        },
        MuiChip: {
          styleOverrides: {
            root: {
              borderRadius: '0px !important'
              // custom style
            },
          }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    lineHeight: 0.5,
                    "&$animated": {
                        lineHeight: 1
                    }
                }
            }
        }
    }
    }),
    []
  );

  const theme = createTheme(themeOptions);

  return (
    <MUIThemeProvider theme={theme}>
      {children}
    </MUIThemeProvider>
  );
}
