import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import { capitalize } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

type ColorTypes =
    | "primary"
    | "secondary"
    | "error"
    | "success"
    | "warning"
    | "default"
    | "inherit"
    | "info";

type CustomProps = { label?: string | React.ReactElement, color?: ColorTypes ,style?:any } & Omit<ButtonProps, "color">;

const useStyles = makeStyles()(theme => ({
    outlinedSuccess: {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main
    },
    outlinedError: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
    },
    outlinedWarning: {
        borderColor: theme.palette.warning.main,
        color: theme.palette.warning.main
    },
    outlinedInfo: {
        borderColor: theme.palette.info.main,
        color: theme.palette.info.main
    },
    containedSuccess: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.success.dark
        }
    },
    containedError: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        }
    },
    containedWarning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.warning.dark
        }
    },
    containedInfo: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.info.dark
        }
    }
})
);


const CustomButton = React.memo((props: CustomProps) => {
    const { label, color, children, className, ...rest } = props;
    const colorName = color ?? "primary";
    // const defaultClassName = classes?.[`${props.variant}${capitalize(colorName)}`];
    const defaultClassName = '';
    const colorProp =
        ["inherit", "primary", "secondary","error","success", "warning"].indexOf(colorName) > -1
            ? (colorName as "inherit" | "primary" | "secondary")
            : undefined;

    return (
        <Button
            color={colorProp}
            variant="contained"
            className={defaultClassName + ' ' + className}
            {...rest}
        >
            {children ? children : <span style={{ textTransform: "none" }}>{label ?? <FormattedMessage id="default" />}</span>}
        </Button>
    )
});

export { CustomButton };