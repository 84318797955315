
export enum FileAcceptData {
  ANY = ".jpg,.jpeg,.png,.gif,.pdf,.docx,.doc,.xlsx,.xls,.webp",
  IMAGES = ".jpg, .jpeg, .png, .webp",
  DOCS = ".pdf,.docx,.doc,.xlsx,.xls",
}

export function fileValidate(value: any = { type: '', size: '' }, t: 'image' | "file" = "image") {
  const { type, size } = value;
  if (!type && !size) return 'File not uploaded or invalid file';
  const isValidSize = +size / 1024 / 1024 >= 3;
  if (t === 'image' && type && !(type === "image/png" || type === "image/jpg" || type === "image/jpeg" || type === "image/gif" || type === "image/webp")) {
    return ".png, .jpg, .jpeg .gif, .webp supported only";
  } if (t === 'file' && type && !(type === "application/pdf" || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || type === 'application/pdf' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
    return ".pdf, .xlsx, .xls supported only";
  } else if (isValidSize) {
    return `Maximum size 3 MB. Uploaded size is ${(+size / 1024 / 1024).toFixed(2)} MB`;
  } else {
    return true;
  }
}

export const images = {
  defaultAvatar: require("assets/images/default-avatar.png"),
  preview: require("assets/images/preview.png"),
  logo: require("assets/images/new-logo.png"),
  document: require("assets/images/no-image.jpeg"),
};
