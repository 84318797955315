import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Stack, Typography } from '@mui/material';
import { fShortenNumber } from 'utils/global/formatNumber';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const IconWrapperStyle = styled('div')(({ theme }: any) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));


const IconWrapperStyle2 = styled('div')(({ theme }: any) => ({
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
}));

export function AppWidgetSummary({ title, total, icon, to, color = 'primary', sx, ...other }: any) {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme: any) => theme.palette[color].darker,
        bgcolor: (theme: any) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <IconWrapperStyle
        sx={{
          color: (theme: any) => theme.palette[color].dark,
          backgroundImage: (theme: any) => `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
        }}
      >
        {icon}
      </IconWrapperStyle>

      <Link to={to} style={{ textDecoration: 'none' }}>
        <Stack sx={{  color: (theme: any) => alpha(theme.palette[color].darker, 1)}}>
        <Typography variant="h3" color='inherit'>{fShortenNumber(total)}</Typography>
        <Typography variant="subtitle2" color='inherit' sx={{ opacity: 0.72 }}>
          {title}
        </Typography>
        </Stack>
      </Link>
    </Card>
  );
}


export function AppCandidateSummary({ title, total, icon, color = '#161C24', sx, ...other }: any) {
  return (
    <Card
      sx={{
        py: 3,
        boxShadow: 0,
        textAlign: 'center',
        color: alpha(color, 1),
        bgcolor: alpha(color, 0.1),
        ...sx,
      }}
      {...other}
    >
      <IconWrapperStyle
        sx={{
          color: alpha(color, 1),
          backgroundImage: (theme: any) => `linear-gradient(135deg, ${alpha(color, 0)} 0%, ${alpha(color, 0.24)} 100%)`
        }}
      >
        {icon}
      </IconWrapperStyle>
      <Link to={`/admin/candidates/list/${title}`} style={{ textDecoration: 'none' }}>
        <Stack sx={{  color: (theme: any) => alpha(color, 1)}}>
          <Typography variant="h3" >{fShortenNumber(total)}</Typography>
          <Typography variant="subtitle2" sx={{  opacity: 0.72, textTransform: 'capitalize' }}>
            {title == 'all' ? 'Total Candidate' : title}
          </Typography>
        </Stack>
      </Link>
    </Card>
  );
}


export function AppCardSummary({ title, total, icon, color = '#F9F9F9', sx, to, ...other }: any) {
  return (
    <Card
      component={Stack}
      spacing={3}
      direction="row"
      sx={{
        px: 2,
        py: 3,
        color: (theme: any) => theme.palette[color].darker,
        bgcolor: (theme: any) => alpha(theme.palette[color].lighter, 0.1),
        border: (theme: any) => '2px solid ' + theme.palette[color].light,
        // bgcolor: alpha('#F9F9F9', 0.1),
        ...sx,
      }}
      {...other}
    >
      <IconWrapperStyle2
        sx={{
          color: (theme: any) => alpha(theme.palette[color].darker, 1),
          backgroundImage: (theme: any) => `linear-gradient(135deg, ${alpha(theme.palette[color].darker, 0)} 0%, ${alpha(theme.palette[color].darker, 0.24)} 100%)`
        }}
      >
        {icon}
      </IconWrapperStyle2>
      <Link to={to} style={{ textDecoration: 'none' }}>
        <Stack spacing={0.5} sx={{ color: (theme: any) => alpha(theme.palette[color].darker, 1) }}>
          <Typography variant="h4">{fShortenNumber(total)}</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72, textTransform: 'capitalize' }}>
            {title}
          </Typography>
        </Stack>
      </Link>
    </Card>
  );
}
