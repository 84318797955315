import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { handleAxiosError, validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate } from 'utils/constants';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, setValue, setError, register, clearErrors, watch, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { status: 1, rating: 5 }
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState({
        image: '',
    })

    function onSubmit(data: any) {
        setLoading(true);
        data.image = data?.image?.[0];
        data.status = data?.status ? 1 : 0;
        if (formMode === 'update') data.id = selectedData?.id;
        const apiFunc = formMode === 'create' ? 'createTestimonial' : 'updateTestimonial';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "survey" }));
                closeModal();
                refreshData();
            })
            .catch((error: any) => {
                setLoading(false);
                handleAxiosError(error, toastMessage, formatMessage, setError);
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                name: selectedData.name,
                reviews: selectedData.reviews,
                designation: selectedData.designation,
                address: selectedData.address,
                order: selectedData.order,
                rating: selectedData.rating,
                status: selectedData.status,
                image: null,
            });
            setFileImage({ image: selectedData.image })
        }

    }, [formMode === 'update',selectedData]);


    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='sm'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "name" })}
                        name="name"
                        control={control}
                        error={errors?.name}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "designation" })}
                        name="designation"
                        control={control}
                        error={errors?.designation}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "address" })}
                        name="address"
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        name="reviews"
                        control={control}
                        placeholder={formatMessage({ id: "reviews" })}
                        minRows={5}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        {...register('image', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                        })}
                        label={formatMessage({ id: "image" })}
                        error={errors?.image}
                        defaultFileUrl={fileImage.image}
                        defaultFileName={''}
                        onClear={() => {
                            setValue(`image`, null);
                            clearErrors(`image`);
                        }}
                        onChange={(file: any) => setValue('image', file)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextBox
                        type='number'
                        label={formatMessage({ id: "rating" })}
                        name="rating"
                        control={control}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextBox
                        type='number'
                        label={formatMessage({ id: "order" })}
                        name="order"
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="status"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;
