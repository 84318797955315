import React from 'react';
import { IconButtonProps } from '@mui/material/IconButton';
import { useIntl } from 'react-intl';

import { CustomDialog } from 'components/CustomDialog';
import { Button, ButtonProps } from '@mui/material';

interface Props extends IconButtonProps {
    onConfirm: any;
    buttonProps: ButtonProps,
    buttonLabel?: string;
    title: string;
    subTitle?: string;
    showConfirmation: boolean;
}

const ConfirmationIconButton = (props: Props) => {
    const [open, setOpen] = React.useState(false);
    const { showConfirmation, buttonLabel, title, subTitle, onConfirm, buttonProps } = props;

    const handleClickOpen = () => {
        if (showConfirmation) {
            setOpen(true);
        } else {
            onConfirm();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onAgree = () => {
        onConfirm();
        handleClose();
    }

    return (
        <>
            <Button
                onClick={handleClickOpen}
                {...buttonProps}
            >{buttonLabel}
            </Button>

            {showConfirmation && (
                <CustomDialog
                    open={open}
                    onDisagree={handleClose}
                    onAgree={onAgree}
                    title={title}
                    subTitle={subTitle}
                />
            )}
        </>
    )
};

ConfirmationIconButton.defaultProps = {
    showConfirmation: true
}

export { ConfirmationIconButton };