import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { handleAxiosError, validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData?: any;
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData} = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, setError, control, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { status: 1 }
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        data.base_price = data.base_price ? data.base_price : 0;
        data.actual_price = data.base_price ? data.base_price : 0;
        data.status = data?.status ? 1 : 0;
        if (formMode === 'update') data.id = selectedData?.id;
        const apiFunc = formMode === 'create' ? 'createPaymentMethod' : 'updatePaymentMethod';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "payment-method" }));
                closeModal();
                refreshData(res.result);
            })
            .catch((error: any) => {
                setLoading(false);
                handleAxiosError(error,toastMessage,formatMessage,setError);
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset(selectedData)
        }

    }, [formMode === 'update']);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "name" })}
                        name="name"
                        control={control}
                        error={errors?.name}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomCheckBox
                        name="status"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;