import { SubscriptionDenied } from 'pages';
import AccessDenied from 'pages/AccessDenied';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { roleData } from 'utils/constants';
import { findCommonItems } from 'utils/global';
import { RootReducerState } from 'utils/types';

const PrivateRoute = ({ children, roles, hasSubscribed }: { children: JSX.Element, roles: Array<string>, hasSubscribed: boolean }) => {
  let location = useLocation();

  const { data, has_subscribed } = useSelector(({ auth }: RootReducerState) => ({ data: auth.data, has_subscribed: auth.has_subscribed }));

  const userHasRequiredRole = findCommonItems(roles, data.role);

  if (!data?.token) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (!data?.user?.role?.includes(roleData.superadmin) && hasSubscribed && has_subscribed == 0) {
    return <SubscriptionDenied />; // build your won access denied page (sth like 404)
  }


  if (data?.token && !userHasRequiredRole) {
    return <AccessDenied />; // build your won access denied page (sth like 404)
  }


  return children;
};
PrivateRoute.defaultProps = {
  hasSubscribed: true
}

export default PrivateRoute;