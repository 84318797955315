import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import { Drawer, } from '@mui/material';

// hooks
import useResponsive from '../../hooks/useResponsive';

// components
import Logo from './Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import sidebarMenu from 'routes/menu';
import { RootReducerState } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { updateUtility } from 'redux/actions';

const LogoStyle = styled('div')(({ theme }: any) => ({
  // padding: '0 24px',
  display: 'inline-flex',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
  background: "inherit",
  // minHeight: 65
}));


export default function DashboardSidebar() {

  const { toggleSidebarMenu } = useSelector(({ utilityReducer }: RootReducerState) => ({ toggleSidebarMenu: utilityReducer.toggleSidebarMenu }));

  const dispatch = useDispatch();

  const isDesktop = useResponsive('up', 'lg');

  const DRAWER_WIDTH = toggleSidebarMenu ? 240 : 0;

  const RootStyle = styled('div')(({ theme }: any) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: DRAWER_WIDTH,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }));

  const { pathname } = useLocation();


  function handleCloseSidebar() {
    dispatch(updateUtility({ toggleSidebarMenu: false }))
  }

  useEffect(() => {
    if (!isDesktop && toggleSidebarMenu) {
      handleCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <LogoStyle>
        <Logo disabledLink />
      </LogoStyle>
      <Scrollbar>
        <NavSection navConfig={sidebarMenu} />
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={toggleSidebarMenu}
          onClose={handleCloseSidebar}
          PaperProps={{
            sx: { 
              width: DRAWER_WIDTH ,
              bgcolor: 'primary.main',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'primary.main',
              borderRightStyle: 'dashed',
              transition: (theme) => theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
