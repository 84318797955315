
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { inquiryColumns } from 'utils/constants/tableColumn/column';

const Index: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const [tableState, setTableState] = useState(tableInitialState);

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.inquiry].data,
            loading: masterPaginationReducer[masterPaginationServices.inquiry].loading,
        }),
        shallowEqual
    );

    const { items, count } = userData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall() {
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.inquiry, params));
    }

    return (
        <PageLayout title={formatMessage({ id: "inquiry" })} hideToolbar>
            <CustomTable
                columns={[...inquiryColumns(formatMessage)]}
                tableData={items}
                tableState={tableState}
                itemsCount={count}
                setTableState={setTableState}
                loading={loading}
            />
        </PageLayout>
    )
}

export default Index;
