import Dashboard from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import { BrandingWatermark, Comment, ContactSupportSharp, FeedbackOutlined, Info, MedicalInformation, Payment, PermIdentity, QuestionAnswer, Settings, Subscriptions, SubscriptionsOutlined } from '@mui/icons-material';
import AttributionIcon from '@mui/icons-material/Attribution';

import { roleData } from '../utils/constants/default';

const SidebarMenu = [
  {
    title: 'dashboard',
    path: '/admin/dashboard',
    icon: <Dashboard />,
    role: [roleData.superadmin]
  },
  // {
  //   title: 'Profile',
  //   path: '/admin/profile',
  //   icon: <Info />,
  //   role: [roleData.superadmin],
  // },
  // {
  //   title: 'Subscription',
  //   path: '/admin/subscriptions',
  //   icon: <Subscriptions />,
  //   role: [roleData.superadmin],
  // },
  {
    title: 'Banner',
    path: '/admin/banners',
    icon: <BrandingWatermark />,
    role: [roleData.superadmin],
  },
  {
    title: 'Faq',
    path: '/admin/faqs',
    icon: <QuestionAnswer />,
    role: [roleData.superadmin],
  },
  {
    title: 'Inquiries',
    path: '/admin/inquiries',
    icon: <ContactSupportSharp />,
    role: [roleData.superadmin],
  },
  {
    title: 'Feedbacks',
    path: '/admin/feedbacks',
    icon: <FeedbackOutlined />,
    role: [roleData.superadmin],
  },
  {
    title: 'Testimonial',
    path: '/admin/testimonials',
    icon: <Comment />,
    role: [roleData.superadmin],
  },
  {
    title: 'User',
    path: '/admin/users',
    icon: <AttributionIcon />,
    role: [roleData.superadmin]
  },
  {
    title: 'Setting',
    path: '/admin/settings',
    icon: <Settings />,
    role: [roleData.superadmin],
    children: [
      {
        title: 'Role',
        path: '/admin/settings/roles',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },
      // {
      //   title: 'Permission',
      //   path: '/admin/settings/permissions',
      //   icon: <PermIdentity />,
      //   role: [roleData.superadmin]
      // },
      {
        title: 'Survey',
        path: '/admin/settings/surveys',
        icon: <Payment />,
        role: [roleData.superadmin]
      },
      {
        title: 'Medications',
        path: '/admin/settings/medications',
        icon: <MedicalInformation />,
        role: [roleData.superadmin],
      },
      {
        title: 'Medication Types',
        path: '/admin/settings/medication-types',
        icon: <MedicalInformation />,
        role: [roleData.superadmin],
      },
      // {
      //   title: 'Payment Method',
      //   path: '/admin/settings/payment-method',
      //   icon: <Payment />,
      //   role: [roleData.superadmin]
      // },
      // {
      //   title: 'Subscription Feature',
      //   path: '/admin/settings/subscription-features',
      //   icon: <GroupIcon />,
      //   role: [roleData.superadmin]
      // },
      // {
      //   title: 'Subscription Plan',
      //   path: '/admin/settings/subscription-plans',
      //   icon: <SubscriptionsOutlined />,
      //   role: [roleData.superadmin]
      // },
      // {
      //   title: 'Subscription View',
      //   path: '/admin/settings/subscription-view',
      //   icon: <SubscriptionsOutlined />,
      //   role: [roleData.superadmin]
      // },
    ]
  },
];

export default SidebarMenu;
