import DashboardLayout from "layouts/dashboard";
import {
  Agency,
  AgencyForm,
  Role,
  SubsciptionFeature,
  Dashboard,
  Setting,
  User,
  UserForm,
  PaymentMethod,
  Subscription,
  SubscriptionPlanView,
  Permission,
  SubscriptionPlan,
  Survey,
  Medication,
  MedicationType,
  Faq,
  Inquiry,
  Testimonial,
  Banner,
  Feedback,
} from "pages";
import Login from "pages/Login";
import NotFound from "pages/Page404";
import { Navigate, useRoutes } from "react-router-dom";
import { roleData } from "utils/constants";
import PrivateRoute from "./PrivateRoute";

export default function Router() {
  return useRoutes([
    {
      path: "/admin",
      element: (
        <PrivateRoute
          roles={[
            roleData.superadmin,
          ]}
          hasSubscribed={false}
        >
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "dashboard",
          element: (
            <PrivateRoute
              roles={[
                roleData.superadmin,
              ]}
              hasSubscribed={false}
            >
              <Dashboard />
            </PrivateRoute>
          ),
        },
        {
          path: "agencies",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Agency />
            </PrivateRoute>
          ),
        },
        {
          path: "agencies/:formType",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <AgencyForm />
            </PrivateRoute>
          ),
        },
        {
          path: "agencies/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <AgencyForm />
            </PrivateRoute>
          ),
        },
        {
          path: "users",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <User />
            </PrivateRoute>
          ),
        },
        {
          path: "users/:formType",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <UserForm />
            </PrivateRoute>
          ),
        },
        {
          path: "users/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <UserForm />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/medications",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Medication />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/medication-types",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <MedicationType />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/roles",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Role />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/permissions",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Permission />
            </PrivateRoute>
          ),
        },
        // {
        //   path: "profile",
        //   element: (
        //     <PrivateRoute roles={[roleData.superadmin]}>
        //       <Setting />
        //     </PrivateRoute>
        //   ),
        // },
        // {
        //   path: "settings/subscription-features",
        //   element: (
        //     <PrivateRoute roles={[roleData.superadmin]}>
        //       <SubsciptionFeature />
        //     </PrivateRoute>
        //   ),
        // },
        {
          path: "settings/payment-method",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <PaymentMethod />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/surveys",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Survey />
            </PrivateRoute>
          ),
        },
        {
          path: "subscriptions",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Subscription />
            </PrivateRoute>
          ),
        },
        // {
        //   path: "settings/subscription-plans",
        //   element: (
        //     <PrivateRoute roles={[roleData.superadmin]}>
        //       <SubscriptionPlan />
        //     </PrivateRoute>
        //   ),
        // },
        // {
        //   path: "settings/subscription-view",
        //   element: (
        //     <PrivateRoute roles={[roleData.superadmin]} hasSubscribed={false}>
        //       <SubscriptionPlanView />
        //     </PrivateRoute>
        //   ),
        // },
        {
          path: "faqs",
          element: (
            <PrivateRoute roles={[roleData.superadmin]} hasSubscribed={false}>
              <Faq />
            </PrivateRoute>
          ),
        },
        {
          path: "testimonials",
          element: (
            <PrivateRoute roles={[roleData.superadmin]} hasSubscribed={false}>
              <Testimonial />
            </PrivateRoute>
          ),
        },
        {
          path: "banners",
          element: (
            <PrivateRoute roles={[roleData.superadmin]} hasSubscribed={false}>
              <Banner />
            </PrivateRoute>
          ),
        },
        {
          path: "inquiries",
          element: (
            <PrivateRoute roles={[roleData.superadmin]} hasSubscribed={false}>
              <Inquiry />
            </PrivateRoute>
          ),
        },
        {
          path: "feedbacks",
          element: (
            <PrivateRoute roles={[roleData.superadmin]} hasSubscribed={false}>
              <Feedback />
            </PrivateRoute>
          ),
        },
        { path: "404", element: <NotFound /> },
      ],
    },
    { path: "/", element: <Login /> },
    { path: "*", element: <Navigate to="/admin/404" replace /> },
  ]);
}
