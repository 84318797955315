import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';

import { Box, Button, Container, Stack, Typography, Card, CardHeader, IconButton } from '@mui/material';
import { ArrowBack, Save } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface Props {
  children: React.ReactNode | React.ReactElement | React.ReactNode[] | React.ReactElement[];
  meta?: any;
  title: string;
  formType: 'create' | 'update';
  showFooter?: boolean;
  onSubmit?: () => void;
}

const FormLayout = forwardRef(({ children, formType, title, meta, onSubmit, showFooter, ...other }: Props, ref: any) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const formText = formType === 'create' ? formatMessage({ id: "create" }) : formatMessage({ id: "update" });
  const headerText = formText + " " + title;
  return <>
    <Helmet>
      <title>{`${headerText} | Pillaxia`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={2} gap={2}>
          <IconButton size="small" color="primary" onClick={() => navigate(-1)} sx={{ border: 1 }}>
            <ArrowBackIcon color='inherit' />
          </IconButton>
          <Typography variant="h6" color="primary"> {headerText}</Typography>
        </Stack>
        {children}
        {showFooter &&
          <Stack direction="row" alignItems="center" justifyContent="flex-end" my={5}>
            <Button variant="outlined" type='button' color="error" onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
              Cancel
            </Button>
            <Button variant="contained" type='submit' color='info' onClick={onSubmit} startIcon={<Save />} style={{ marginLeft: 15 }}>
              {formType === 'create' ? 'Submit' : 'Update'}
            </Button>
          </Stack>
        }
      </Container>
    </Box>
  </>
});

FormLayout.defaultProps = {
  title: 'Home',
  showFooter: true
}

export default FormLayout;


